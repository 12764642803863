.new-plan-btn {
	/* width: 150px; */
	background-color: green !important;
	color: white !important;
}

.new-plan-btn .user-icon, .plans-btn .user-icon{
	color: white !important;
}

.new-plan-btn:hover {
	background-color: rgb(10, 107, 10) !important;
}

.virt-table-cell.order-plan-header {
	background-color: bisque !important;
}

.virt-table-cell.order-plan-header.plan-success {
	background-color: rgb(140, 207, 166) !important;
}

.virt-table-cell.order-plan-header.plan-warning {
	background-color: rgb(228, 171, 86) !important;
}

.remove-domain-popup-content {
	max-width: 400px !important;
}

.remove-domain-popup-content .new-order-plan-title{
	font-weight: 600;
	font-size: 18px;
}

.remove-domain-popup-title {
	text-align: center;
}

.remove-domain-btn-cont {
	text-align: center;
}

.order-plan-replace-btn {
	background-color: rgb(111, 179, 224) !important;
}
  
.order-plan-delete-btn {
	background-color: rgb(204, 47, 47) !important;
}

.order-plan-edit-anchor-btn {
	background-color: rgb(199, 199, 184) !important;
}

.execute-order-plan-btn .user-icon, .del-order-plan-btn .user-icon {
	color: white;
}
.order-plan-button-cont {
	border-top: 1px solid #d7d1d1;
	border-bottom: 1px solid #d7d1d1;
}