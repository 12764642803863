@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.new-board-popup-content {
	width: 400px !important;
}

.new-board-btn-cont {
	border-top: 1px solid #d7d1d1;
	border-bottom: 1px solid #d7d1d1;
}

.new-board-btn {
	/* width: 150px; */
	background-color: green !important;
	color: white !important;
}

.new-board-btn svg{
	color: white;
}

.new-board .new-bord-title {
	text-align: center;
	font-weight: 600;
	font-size: 18px;
}

.new-board .close-btn-cont {
	text-align: end;
}

.save-btn-cont {
	text-align: center;
}

.save-board-btn {
	background-color: green !important;
	color: white !important;
}

.create-error {
	color:#cc1818;
}