@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.plans-modal {
    max-width: 1500px !important;
    /* width: 1000px !important; */
}
.plan-editor-cont {
    height: 100%;;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.plan-header {
    font-weight: 600;
    font-size: 17px;
}
.plan-btn {
    border: 2px solid #31748d;
    border-radius: 10px;
    text-align: center;
    padding: 6px 0;
    font-weight: 400;
    color: #31748d;
    font-weight: 500;
}
.plan-btn:hover {
    cursor: pointer;
    background-color: #31748d;
    color: white;
}
.plan-btn:hover .user-icon{
    color: white !important;
}
.plan-btn.active-plan {
    background-color: #31748d;
    color: white;
}
.plan-btn.active-plan .user-icon {
    background-color: #31748d;
    color: white;
}
.plan-number-input {
    width: 50px;
    text-align: center;
}
.percent-label {
    display: inline-block;
    /* width: 20px; */
    border: 1px solid black;
    padding: 2px 6px;
    position: relative;
    left: -1px;
}
.input-percent-cont {
    display: flex;
    align-items: center;
    height: 100%;
}
.add-dr-btn {
    padding: 5px 8px;
    background-color: #0c1e35;
    color: #fff;
    border: 1px solid #0c1e35;
    border-radius: 4px;
    max-width: 154px;
}
.add-dr-btn:hover {
    cursor: pointer;
    background-color: #fff;
    color: #0c1e35;
    border-color: #0c1e35;
}
.del-plan-dialog{
    min-width: 500px;
}
.del-plan-dialog .dropdown-menu.show{
    padding: 15px;
}
.confirm-plan-del {
    max-width: 160px !important;
    margin-right: 10px;
}
.cancel-plan-del {
    max-width: 120px !important;
}
.plan-buttons-cont{
    border-right: 1px solid #dee2e6;
}
.plan-form-section-end {
    border-bottom: 1px solid #dee2e6;
}
.percentage-warning {
    color: red;
}