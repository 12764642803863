.accountPage .row>* {
    padding: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.accountTable table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.205) !important;
}

.am_box {
    padding: 5%;
    color: white;
    /* background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(119, 49, 9, 1) 0%, rgba(9, 84, 120, 1) 20%, rgba(9, 85, 121, 1) 49%, rgba(0, 212, 255, 1) 100%); */
    background-color: #0C1E35;
}

.ml-5 {
    margin-left: 10%;
}

.userInfo h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.25;
}

.userInfo h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    display: flex;

}

.userInfo h4 svg {
    color: #7b26c1;
    border: 1px solid #7b26c1;
    font-size: 14px;
    padding: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.userInfo h4 p {
    padding-left: 5px;
    color: #272727;
    font-size: 17px;
}

.centerPosiion {
    margin-left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.tableBox .btnLeft {
    float: left;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.addForm .btnRight {
    float: right;
    padding: 6px 28px;
    background-color: rgb(36, 160, 243);
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    color: #fff;
}

.deleteDorm .deleteLeft {
    background-color: rgb(26, 126, 192) !important;
    float: left;
    color: #fff;
}

.deleteDorm .deleteRight {
    background-color: rgb(235, 26, 130) !important;
    float: right;
    color: #fff;
}

.addForm h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 10px;
}

.tableBox .deleteBtn {
    background-color: #fb156b;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
}

.tableBox .addBtn {
    float: right;
    background-color: #3a86e8;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.tableBox .addBtn:hover {
    border: 1px solid #3a86e8 !important;
    color: #3a86e8 !important;
    background-color: transparent;
}

.btnUpdate {
    padding: 6px 28px;
    background-color: rgb(36, 160, 243) !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff !important;
}

.tableBox .userInfo p {
    font-size: 16px;
    font-weight: 500;
}

.tableBox .userInfo p span {
    font-size: 18px;
    font-weight: 600;
}

.tableBox .userInfo p span:nth-child(2), .tableBox .userInfo p span:nth-child(3) {
    padding-left: 10px;
}

.userInfo .passwordBtn {
    /* background-color: #fb156b; */
    font-size: 16px;
    font-weight: 500;
    color: rgb(22, 22, 22);
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    margin-bottom: 5px;
}

.userInfo .btn-group .dropdown-menu {
    min-width: 405px !important;
    padding: 10px;
    top: -40px !important;
}

.dropdown-menu .password {
    float: left;
}

.dropdown-menu .password p {
    background-color: rgb(91, 241, 22);
    text-align: center;
    font-size: 15px;
    line-height: 22px;
}

.dropdown-menu .passwordBtn {
    float: left;

}

.dropdown-menu .passwordBtn button {
    margin-left: 10px;
    background-color: rgb(247, 247, 247);
    border: 1px solid #333;
}

.dropdown-menu .passwordBtn button svg {
    font-size: 15px;
}

/* modal */
.tableBox .popup-content {
    margin: auto;
    background: #fff;
    width: 33% !important;
    padding: 5px;
    border: 1px solid #d7d7d7;
    padding: 40px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.addForm label {
    font-size: 17px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 5px;
    display: block;
}
.addForm select{
    width: 100%;
padding: 12px 15px;
border-radius: 5px;
background: rgb(255, 255, 255);
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
width: 100%;
margin-bottom: 10px;
}

.addForm .modalSave {
    background-color: red;
    font-size: 16px;
    color: #272727;
}

/* .addForm input[type=text] {
    width: 100%;
    padding: 2px 10px;
    margin: 8px 0;
    box-sizing: border-box;
  } */
.addForm .closeBtn {
    padding: 6px 28px;
    background-color: rgb(228, 27, 127);
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    color: #fff;
}
.userIcon{
    color: rgb(36, 160, 243);
}
.userBtn{
    display: flex;
}
.userBtn button{
border: 1px solid rgb(36, 160, 243);
}

.monday-status {
	border-top: 1px solid #d7d1d1;
	border-bottom: 1px solid #d7d1d1;
}
.monday-status-text {
	font-weight: 600;
	font-size: 18px;
}
.monday-btn:hover {
	cursor: pointer !important;
}
.monday-popup-content {
	width: 500px !important;
	border-radius: 8px;
}
.monday-popup-content .title {
	text-align: center;
	font-weight: 600;
}
.monday-popup-content .button-cont {
	text-align: end;
}
.monday-popup-content .button-cont .confirm-btn {
	margin-right: 5px;
}