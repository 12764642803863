/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* Background Color */

.bg_color {
    background-color: #56baec;
}


/* Basic Button Collor */

.basic_button {
    background-color: #56baee;
    color: #fff;
}


/* Fonts */

.font_poppins {
    font-family: "Poppins", sans-serif;
}

.font_roboto {
    font-family: "Roboto", sans-serif;
}

.main {
    background-color: whitesmoke;
height: 100vh !important;
    width: 100%;
    overflow-y: auto;
}

.page-cont{
    height: calc(100vh - 120px);
}

@media(max-width:778px) {
    .main {
        padding: 30px 0px;
    }
}

@media (min-width:1500px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
     max-width:1520px !important
    }
   }
   @media (min-width:1800px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
     max-width:1720px !important
    }
   }