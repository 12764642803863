@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.rightBox {
  padding: 10px 30px !important;
}

table {
  /* border-radius: 100px !important; */
}

.page-table-cont {

}

.sticky-top {
  position: sticky;
  top: -1px !important;
  z-index: 999 !important;
}

.tableHeading {
  color: rgb(59, 56, 56);
  font-size: 22px;
  font-weight: 800;
  margin: 0px 0px 0px 0px;
  font-family: 'Times New Roman', Times, serif;
}

.subTableHeading{
	margin: 23px 0 12px 0;
    color: #0c1e35;
    font-size: 20px;
    font-weight: 500;
}

.subTableToggle {
	margin-left: 8px;
}

.subTableToggle:hover {
	cursor: pointer;
}

.dataTableCont {
	transition: max-height 0.5s ease-in;
}

.dataTableCont.closed {
	max-height: 0;
	transition: max-height 0.5s;
}

.logoutBtn a {
  color: #0c1e35 !important;
}

.logout-icon {
  color: red;
}

.tBorder {
  max-height: calc(100vh - 210px);
  font-size: 14px;
  border-radius: 10px;
}


table {
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;
  max-height: 100% !important;
  white-space: nowrap;
  width: 100%;
}

th,
td {
  padding: 11px 8px !important;
}

tbody tr {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;

}

.marginAdd {
  margin-bottom: 8px !important;
}

thead th label {
  margin-bottom: 5px;
}

thead th input {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  background-color: #fff;
  border: 1px solid #222;
  padding: 4px;
}

thead th input:focus {
  background-color: #222;
  box-shadow: none !important;
  border-color: rgb(123, 156, 247) !important;
}


table thead tr th {
  font-size: 15px !important;
  cursor: pointer;
  font-weight: 900;
  padding: 17px 12px 14px 12px !important;
}

table tbody tr th {
  font-size: 13px !important;
  text-align: center;
  font-weight: 600;
}

.tableDropdown tr th {
  background-color: #fff;
  border-bottom: 2px solid #222;
}

.overlayColor {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.178) !important;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999999999;
}

.tableLoadingColor {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.178) !important;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999999999;
  min-height: 450px;
}

.tableLoadingColor img {
  text-align: center;
  position: absolute;
  margin-top: 10%;
}

.boardName {
  color: #25b0f4;
  font-weight: 600;
}

.titleName {
  color: #2c3e50;
  font-weight: 600;
}

/* tooltip */
.tooltipp {
  position: relative;
}

.tooltipp .tooltipptext {
  visibility: hidden;
  width: auto;
  background-color: #0c1e35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 7px;
  position: absolute;
  /* max-width: 300px;
  word-break: break-all; */
  z-index: 91;
  top: 6px;
  right: 93%;
}

.tooltipp:hover .tooltipptext {
  visibility: visible;
}

.searchBox {
  margin: 10px 0px;
}

.searchBox input {
  outline: none;
}

.searchBox input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
  cursor: pointer;
}

.searchBox input::-webkit-search-decoration,
.searchBox input::-webkit-search-cancel-button {
  display: none;
}

.searchBox input[type='search'] {
  /* background: #0c1e35 url(../../../public/search.png) no-repeat 9px center; */
  background: #fff;
  border: solid 2px #085f63;
  color: #085f63;
  padding: 5px 10px 5px 32px;
  width: 55px;
  font-weight: 500;
  border-radius: 18px;
  transition: all 0.5s;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
}

.searchBox input[type='search']::placeholder {
  color: #085f63;
  opacity: 1;
  /* Firefox */
}

.search-icon {
  position: absolute;
  margin-left: 8px;
  color: #085f63;
}

.search-icon:focus {
  position: absolute;
  margin-left: 8px;
  color: #0c1e35 !important;
}

.edit-icon:hover {
	cursor: pointer !important;
}

.editCell {
	text-align: center;
}

.editForm {
	position: relative;
}

.editForm .close-btn-cont {
	text-align: end;
}

.editForm .updateBtn {
	background-color: #28a019 !important;
	color: white !important;
}

.editForm .editRowHeading {
	font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px
}

.editForm .rowEditInput {
	width: 100%;
	padding: 4px;
    border-radius: 5px;
    border: 1px solid gray;
}

.editForm .rowEditInput:disabled {
	background-color: #c5cccc;
}

.searchBox input[type='search']:focus {
  width: 130px;
  background-color: #fff;
  border-color: #085f63;
  color: #085f63 !important;
  cursor: auto;

  -webkit-box-shadow: 0 0 5px #0c1e35;
  -moz-box-shadow: 0 0 5px #0c1e35;
  box-shadow: 0 0 5px #0c1e352f;
}

.searchBox input:-moz-placeholder {
  color: #999;
}

.searchBox input::-webkit-input-placeholder {
  color: #999;
}

.searchBox .tooltipp .tooltipptext::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #0c1e35;
}

.searchBox .tooltipp:hover .tooltipptext {
  visibility: visible;
}

.tooltipBox {
  position: relative;
}

.tooltipBox .tooltipp {
  position: relative;
}


.tooltipBox .tooltipp .tooltipptext {
  visibility: hidden;
  background: black;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 6px;
  position: absolute;
  z-index: 1;
  left: 110%;
}

.tooltipBox .tooltipp .tooltipptext:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltipBox .tooltipp:hover .tooltipptext {
  visibility: visible;
}

.dataDropdown li {
  margin: 0 !important;
  float: none !important;
}

.tBorder table {
  background-color: #fff;
  border: 1px solid #222;
}

.tBorder table tr th {
  border: 2px solid #f1f1e8;

}


.flexcol {
  display: flex !important;
  justify-content: center;
}

.dotBtn {
  border: none;
  color: #222;
  background-color: transparent;
  margin-left: 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dotBtn svg {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 0px 2px;
}


.flexcol .dropdown-menu .dropdown-item {
  padding: 0.5rem 0.5rem;
  text-align: center;
}
.dataDropdown li .dropdown-item button{
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  color: #085f63 !important;
}
.dataDropdown li .dropdown-item-active button{
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  color: #fff !important;
}
.dataDropdown li .dropdown-item span:nth-child(2) {
  font-size: 14px;
  color: #2890c4 !important;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

/* .dataDropdown li .dropdown-item span:nth-child(3),
.dropdown-item-active span:nth-child(3) {
  margin: 0px 4px;
} */

.dataDropdown li .dropdown-item span:nth-child(3) {
  font-size: 14px;
  color: #e61e1e !important;
  position: absolute;
  right: 12px;
  cursor: pointer;
}
.dropdown-item-active span:nth-child(2) {
  
  color: #fff !important;
  padding-left: 4px;
}
.dropdown-item-active span:nth-child(2),
.dropdown-item-active span:nth-child(3){
  color: #fff;
  font-size: 14px;
}

.flexcol .dropdown-menu .dropdown-item button {
  display: flex;
  align-items: center;

}

.flexcol .dropdown-menu .dropdown-item button svg {
  font-size: 14px;
}

.flexcol .dropdown-menu .dropdown-item button span {
  padding-left: 15px;
  font-size: 15px;
  font-weight: 500;
}


/* dropdown */
.flexcol .dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 1rem !important;
  padding: 0rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.form-select-lg {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 5px !important;
  font-size: 13px !important;
  border-radius: .3rem !important;
  -webkit-border-radius: .3rem !important;
  -moz-border-radius: .3rem !important;
  -ms-border-radius: .3rem !important;
  -o-border-radius: .3rem !important;
}

.filterTable .css-12jo7m5 {
  border-radius: 2px;
  color: hsl(0, 0%, 0%) !important;
  font-size: 74% !important;
  overflow: hidden;
  padding: 3px;
  padding-left: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.filterTable .css-14el2xx-placeholder {
  color: hsl(0deg 0% 0%) !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px !important;
}

/* .filterBtn button {
  background-color: #fff;
  color: #085f63;
  margin-left: 15px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
  border-radius: 15px;
  padding: 2px 19px;
  border: 2px solid #085f63;
} */
.filterBtn .filterButton {
  position: relative;
  display: flex !important;
  align-items: center;
  background-color: #fff !important;
  color: #085f63 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 2 !important;
  padding: 0px 25px !important;
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
  border: 2px solid #085f63 !important;
  margin-left: 10px;
}

.filterBtn .dropdown-toggle:hover {
  color: #085f63 !important;
}

.filterTable .row {

  padding-right: 5px !important;
  padding-left: 5px !important;

}

.addBtn .addFilter {
  float: left;
  background-color: #0c1e35;
  padding: 8px 13px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #0c1e35;
  margin-bottom: 20px;
}

.addBtn .addFilter:hover {
  background-color: #fff;
  color: #0c1e35;
  cursor: pointer;
  border: 1px solid #0c1e35;
}

.addBtn .addName {
  float: left;
  background-color: #1867cf;
  padding: 8px 13px;
  margin-left: 15px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #1867cf;
  margin-bottom: 20px;
}

.addBtn .addName:hover {
  background-color: #fff;
  color: #0c1e35;
  cursor: pointer;
  border: 1px solid #0c1e35;
}


.addBtn .nameInput {
  float: left;
  background-color: #f3f3f3;
  padding: 8px 13px;
  color: rgb(2, 2, 2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #1867cf;
  margin-bottom: 20px;
  margin-left: 5px;
}

.dataBtn {
  position: relative;
  display: flex !important;
  align-items: center;
  background-color: #fff !important;
  color: #085f63 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 2 !important;
  padding: 0px 40px 0px 10px !important;
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
  border: 2px solid #085f63 !important;
  margin-left: 10px;
  transition: none !important;
}
.dataBtn .viewBtn, .dataActive .viewBtn{
  min-width: 45px;
  text-align: center;
  background-color: transparent;
  border: none;
}
 .dataActive .viewBtn{
  min-width: 45px;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #fff;
}
.dataBtn:nth-child(1) {
  padding: 0px 20px !important;
}

/* div {
  height:300px;
  width:100%;
  outline:thin solid black;
  position:relative;
  background:white;
} */
.dataBtn::after,
.dataBtn::before {
  height: 100%;
  content: ' ';
  position: absolute;
  top: 0;
  width: 21px;
}

.dataBtn::after {
  right: 0;
  background-color: transparent;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;

}


.dataBtn:hover {
  background-color: #ffffff !important;
  color: #085f63 !important;
}

.dataActive {
  position: relative;
  display: flex !important;
  align-items: center;
  background-color: #085f63 !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 2 !important;
  padding: 0px 40px 0px 10px !important;
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.13);
  border: 2px solid #085f63 !important;
  margin-left: 10px;
  transition: none !important;
}

.dataActive:nth-child(1){
  padding: 0px 20px !important;
}

.dataFlex .dataActive .crossButton {
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #fff !important;
  /* z-index: 9999999; */
  padding-right: 5px;
}

.dataFlex .dataActive .editButton {
  display: block;
  position: absolute;
  right: 21px;
  cursor: pointer;
  font-size: 14px;
  color: #fff !important;
  /* z-index: 9999999; */
  padding-left: 5px;
}

.dataFlex .dropdataBtn {
  background-color: transparent !important;
  color: #085f63 !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  line-height: 2 !important;
  padding: 0px 6px !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border: none !important;
  box-shadow: none !important;
}

.dataFlex .dropdown-item:hover {
  color: #0c1e35 !important;
  background-color: rgb(236, 236, 236) !important;
}

/* .dataFlex .dataBtn:focus {
  background-color: #085f63 !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 2 !important;
  padding: 0px 20px !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
} */

/* .dataFlex .dataBtn::after {
  content: "";
  height: 15px !important;
  border-right: 2px solid #b0b0b0 !important;
  position: absolute;
  display: block;
  right: 0px;
  top: 11px;
} */

.dataFlex .dropdataBtn:active {
  background-color: #085f63 !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  padding: 0px 6px !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border-bottom: none !important;
}

.dataFlex .dataBtn .crossButton {
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #e61e1e !important;
  z-index: 99;
  padding-right: 5px;
}

.dataFlex .dataBtn .crossButton:hover {
  color: #e61e1e !important;
}

.dataFlex .dataBtn .editButton {
  display: none;
}

.dataBtn::before {
  left: 0;
  background-color: transparent;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.dataFlex .dataBtn:hover .editButton {
  display: block;
  position: absolute;
  right: 21px;
  cursor: pointer;
  font-size: 14px;
  color: #2890c4 !important;
  /* z-index: 9999999; */
  padding-left: 5px;
}

.dataFlex .dataDropdown {
  overflow-y: scroll;
  max-height: 226px;
  overflow-x: none;
  padding-right: 5px;
}

.renameBox h1 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #085f63;
}

.renameBox input {
  border: 1px solid #085f63;
}

.renameBox input:focus {
  box-shadow: none;
}

.scroll {
  scrollbar-color: var(--scroll-thumb-color, grey) var(--scroll-track, transparent);
  scrollbar-width: thin;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.scroll::-webkit-scrollbar {
  width: var(--scroll-size, 5px);
  height: var(--scroll-size, 2px);
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-track, #fff);
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, rgb(223, 223, 223));
  background-image: var(--scroll-thumb, none);
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.dropdown-menu-middle {
  margin: 0 auto !important;
}

.dataDropdown li .dropdown-item {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  border: 2px solid #085f63;
  margin: 2px 0px;
  border-radius: 6px;
  font-size: 17px !important;
  padding: 8px 45px 8px 8px;
  text-align: left;
  color: #085f63;
}

.dataDropdown li .dropdown-item-active {
  display: flex;
  align-items: center;
  background-color: #085f63 !important;
  border: 2px solid #085f63;
  width: 100%;
  margin: 2px 0px;
  border-radius: 6px;
  font-size: 17px !important;
  padding: 8px 45px 8px 8px;
  text-align: left;
  color: #fff;
}
.dataDropdown li .dropdown-item-active span:nth-child(2) {
  font-size: 14px;
  color: #fff !important;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

/* .dataDropdown li .dropdown-item span:nth-child(3),
.dropdown-item-active span:nth-child(3) {
  margin: 0px 4px;
} */

.dataDropdown li .dropdown-item-active span:nth-child(3) {
  font-size: 14px;
  color: #fff !important;
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.addBtn .searchBtn {
  float: right;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #17b978;
  color: #fff;
  border: 1px solid #17b978 !important;
  font-weight: 600;
}

.addBtn .searchBtn:hover {
  color: #fff;
  /* background-color: #fff;
  color: #17b978;
  border: 1px solid #17b978 !important;
  float: right;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; */
}

.filterHeading p {
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.selectOption {
  padding: 8px 0px !important;
  width: 50px !important;
}

.clearBtn {
  text-align: end;
}

.clearBtn button {
  background-color: #e70f3c;
  padding: 8px 12px !important;
  color: #fff;
  border: 1px solid #e70f3c !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.clearBtn button:hover {
  background-color: rgb(255, 255, 255);
  padding: 8px 12px !important;
  color: #e70f3c;
  border: 1px solid #e70f3c !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.closeBtn p {
  background-color: #fff;
  color: #0c1e35;
  font-size: 22px;
  cursor: pointer;
}

.css-1rhbuit-multiValue {
  background-color: hsl(0deg 0% 97%) !important;
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  border: 1px solid hsl(0deg 0% 91%) !important;
}

.css-xb97g8:hover {
  background-color: #fff !important;
  color: #DE350B;
}

.success {
  font-weight: 600 !important;
  color: white !important;
  background-color: #17b978 !important;
  cursor: pointer !important;
}

.leftElement ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff !important;
  border: none !important;
}

.leftElement ul li {
  float: left;
  height: auto;
  margin: 10px;
  display: flex;
  align-items: center;
}

.rightElement ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rightElement ul li {
  float: right;
  height: auto;
  margin: 10px;
}

#synchronization {
  background-color: #0c1e35 !important;
  color: white;
}

.tableBtnCont {
	display: flex;
	justify-content: end;
}

.exportBtn {
  background-color: #16a9ee !important;
  color: white !important;
}

.importBtn {
  background-color: #ee7e16 !important;
  color: white !important;
}

.uploadBtn {
  background-color: #21a12c !important;
  color: white !important;
  margin-left: 10px;
}

.sampleBtn {
  background-color: #7697bd !important;
  color: white !important;
}

.exportBtn svg, .importBtn svg, .uploadBtn, .sampleBtn {
  color: #fff;
}

.uploaderHeading {
	font-size: 18px;
    font-weight: 500;
    color: #0c1e35;
}

.csv-warning {
	color:#f1a32d;
}

.csvUploader { 
	min-width: 450px;
}

.uploadNote {
	color:#3ea110;
}

.uploadNote.uploadError {
	color:#cc1818;
}

.filterImage {
  position: absolute;
  margin-top: 65px !important;
  z-index: 999999;
  background-color: whitesmoke;
  width: 100%;
  height: calc(100vh - 85px);
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.filterImage img {
  margin-top: 20%;
  transform: translate(0, -20%);
  -webkit-transform: translate(0, -20%);
  -moz-transform: translate(0, -20%);
  -ms-transform: translate(0, -20%);
  -o-transform: translate(0, -20%);
}

.loadingImage {
  align-items: center;
  position: relative;
}

.loadingImage img {
  margin-top: 20%;
  transform: translate(0, -20%);
  -webkit-transform: translate(0, -20%);
  -moz-transform: translate(0, -20%);
  -ms-transform: translate(0, -20%);
  -o-transform: translate(0, -20%);
}

#react-select-5-listbox,
#react-select-7-listbox {
  height: auto !important;
  overflow: hidden;
}

.react-select-listbox {
  height: 150px !important;
}

.state-div {
  margin-left: 30px;
  margin-right: 60px;

  margin-top: 15px;
}

.state-div p {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.badge {
  display: inline-block;
  padding: 8px 12px !important;
  font-size: .75em;
  font-weight: 600 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.edit-popup-content {
  margin: auto;
  background: #fff;
  width: 40% !important;
  border: 1px solid #d7d7d7;
  padding: 30px !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* .boardname p {
  margin-top: 13px;
} */

/* dropdown */

.filterBtn .dropdown {
  margin: 0 auto;
  /* z-index: 10000; */
  position: relative;
}

.filterBtn .dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  font-weight: 500;
}

.dropdownFilter .dropdown-menu {
  position: absolute;
  z-index: 10000;
  left: 0;
  min-width: 52rem !important;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  margin-top: 5px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.dropdownFilter .dropdown-menu .item {
  padding: 10px;
  cursor: pointer;
}

.dropdownFilter .dropdown-menu .item:hover {
  background: #fcfcfc;
}

table th.virt-table-col {
  background-color: #fff;
  z-index: 90;
}

table td.virt-table-cell {
  background-color: #fff;
}

table tr th.virt-table-col {
  border: 2px solid #f1f1e8;
  /* border: 3px solid #eb0d0d; */
}

table tr th.virt-table-col.edit-cell {
  width: 75px;
}

table tr th.virt-table-col.button-col {
  width: 125px !important;
}

table tr td.virt-table-cell {
  border: 2px solid #f1f1e8;
}

table td.checkbox-cell {
  text-align: center;
}

table td.name-cell {
  font-weight: 500;
}

table td.virt-table-cell.group-header-cell {
  text-align: center;
}

table td.virt-table-cell.group-header-cell.default-group-header {
  background-color: #dff9f9;
}

table th.sticky-cell {
  background-color: #fff;
  position: relative;
  z-index: 90;
  left: -1px;
  position: sticky;
}

.tBorder table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.089) !important;
  z-index: 99;
  opacity: 1;
  position: relative;
}

.table>:not(:first-child) {
  border-top: 0px solid currentColor;
}

.collapseIcon {
  background-color: #2173dd !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 18px !important;
  color: #fff !important;
  cursor: pointer !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  margin-left: 5px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #fdfdfd !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.non-btn {
  border: none !important;
}
.tbl-save-btn .user-icon{
  color: white;
}

.dropdown-col-header .btn{
  padding: 2px;
  width: 100%;
}