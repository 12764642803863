@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
.navigation {
  /* width: 100%;
  position: fixed;
  top: 0px; */
 width: auto;
  position: relative;
  /* height: 57px !important;
  background-color: rgb(255, 255, 255); */
  z-index: 9; 

}
.navigation nav{
  background-color: #fff;
}
.nav-item p {
  color: #0c1e35 !important;
  margin: 0px !important;
}
.nav-item p svg {
  font-size: 22px;
}
.nav-item button svg{
  color: #fff;
}
.logoutBtn {
  padding-left: 7px;
}
.logoutBtn a {
  color: #0c1e35 !important;
}

.logout-icon {
  color: red;
}

.user-icon {
  color: #364f6b;
}

.user-name svg{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

#synchronization {
  background-color: #0c1e35 !important;
  color: white;
}

#la_calculation {
  background-color: #375c8d !important;
  color: white;
  margin-left: 10px;
}
.stateBox h1{
  color: rgb(59, 56, 56) !important;
font-size: 28px !important;
text-align: center;
margin-bottom: 5px !important;
font-family: 'Times New Roman', Times, serif;
}
.stateBox h1:nth-child(2){
  color: rgb(59, 56, 56) !important;
font-size: 18px !important;
text-align: center;
margin-bottom: 1px !important;
font-family: 'Times New Roman', Times, serif;
}
.stateBox h1:nth-child(2) .dateText{
  color: rgb(59, 56, 56) !important;
font-size: 15px !important;
text-align: center;
margin-left: 5px;
margin-bottom: 1px !important;
font-family: 'Times New Roman', Times, serif;
}
.state-div{
  margin-left: 30px;
  margin-right: 60px;

  margin-top: 15px;
}
.state-div p{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
.leftElement ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
.leftElement ul li {
  float: left;
  height: auto;
  margin: 10px;
}
.state-popup-content {
	margin: auto;
	background: #fff;
	width: 40% !important;
	border: 1px solid #d7d7d7;
	padding: 30px !important;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}