@import '~react-pro-sidebar/dist/scss/styles.scss';
$sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$submenu-indent: 14px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(0, 0, 0, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35,
    0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255,
    255,
    255,
    0.05);
$icon-size: 35px;
@import '~react-pro-sidebar/dist/scss/styles.scss';

body {
    margin: 0;
    height: 100vh;
    color: #353535;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: flex;
    position: relative;

    .btn-toggle {
        cursor: pointer;
        width: 35px;
        height: 35px;
        background: #353535;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        display: none;
    }

    .sidebar-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .sidebar-btn {
            transition: width 0.3s;
            width: 150px;
            padding: 1px 15px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.05);
            color: #adadad;
            text-decoration: none;
            margin: 0 auto;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-overflow: ellipsis;
            overflow: hidden;

            span {
                margin-left: 5px;
                font-size: 13px;
            }

            &:hover {
                color: $highlight-color;
            }
        }
    }

    .collapsed {
        .sidebar-btn {
            display: inline-block;
            line-height: 40px;
            padding: 0;
        }
    }

    main {
        padding: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        header {
            margin-bottom: 40px;
            border-bottom: 1px solid #efefef;

            h1 {
                display: flex;
                align-items: center;
                transform: translateX(-20px);
            }
        }

        footer {
            margin-top: auto;
            color: #888;
            text-align: center;

            a {
                text-decoration: none;
                color: #888;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .social-bagdes {
        margin-top: 10px;

        img {
            margin: 5px;
        }
    }

    .block {
        display: flex;
        margin-bottom: 24px;
        font-size: 14px;
        color: #545454;

        >span {
            margin-left: 10px;
        }
    }

    &.rtl {
        direction: rtl;
        text-align: right;

        header {
            h1 {
                transform: translateX(20px);
            }
        }

        .block {
            direction: rtl;

            >span {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        .sidebar-btn-wrapper {
            .sidebar-btn {
                span {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 767.98px) {
        overflow-x: hidden;

        header {
            h1 {
                font-size: 24px;
            }
        }
    }

    @media (max-width: $breakpoint-md) {
        .btn-toggle {
            display: flex;
        }

        &.rtl {
            .btn-toggle {
                margin-left: auto;
            }
        }
    }
}

.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;

    &.red {
        color: #ffffff;
        background: #d63030;
    }

    &.gray {
        color: #ffffff;
        background: #777777;
    }

    &.yellow {
        color: #000000;
        background: #ffd331;
    }
}

%pro-item-prefix {
    &:before {
        content: '';
        display: inline-block;
        width: 4px;
        min-width: 4px;
        height: 4px;
        border: 1px solid $submenu-bg-color;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;
        box-shadow: 1px 0px 0px $sidebar-color, 0px -1px 0px $sidebar-color, 0px 1px 0px $sidebar-color, -1px 0px 0px $sidebar-color;
    }
}

%text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar {
    .pro-menu {
        padding-top: 5px;
        padding-bottom: 10px;

        >ul {
            >.pro-sub-menu {
                >.pro-inner-list-item {
                    position: relative;
                    background-color: $submenu-bg-color;

                    >div>ul {
                        padding-top: 2px;
                        padding-bottom: 15px;
                    }
                }
            }
        }

        a {
            text-decoration: none;
            color: $sidebar-color;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: transparent;
            }

            &:hover {
                color: $highlight-color;
            }
        }

        .pro-menu-item {
            font-size: 15px;

            &.active {
                color: $highlight-color;
            }

            .suffix-wrapper {
                opacity: 1;
                transition: opacity 0.2s;
            }

            .prefix-wrapper {
                display: flex;
                margin-right: 5px;
                opacity: 1;
                transition: opacity 0.2s;
            }

            >.pro-inner-item {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 35px 8px 19px;
                cursor: pointer;

                &:focus {
                    outline: none;
                    color: $highlight-color;
                }

                >.pro-icon-wrapper {
                    margin-right: 10px;
                    font-size: 14px;
                    width: $icon-size;
                    min-width: $icon-size;
                    height: $icon-size;
                    line-height: $icon-size;
                    text-align: center;
                    display: inline-block;

                    .pro-icon {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                    }
                }

                >.pro-item-content {
                    flex-grow: 1;
                    flex-shrink: 1;
                    @extend %text-truncate;
                }

                &:hover {
                    color: $highlight-color;

                    .pro-icon-wrapper {
                        .pro-icon {
                            animation: swing ease-in-out 0.5s 1 alternate;
                        }
                    }
                }
            }

            &.pro-sub-menu {
                >.pro-inner-item {
                    &:before {
                        background: $sidebar-color;
                    }

                    >.pro-arrow-wrapper {
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);

                        .pro-arrow {
                            display: inline-block;
                            border-style: solid;
                            border-color: $sidebar-color;
                            border-width: 0 2px 2px 0;
                            padding: 2.5px;
                            vertical-align: middle;
                            transition: transform 0.3s;
                            transform: rotate(-45deg);
                        }
                    }
                }

                &.open {
                    >.pro-inner-item {
                        &:before {
                            background: transparent !important;
                        }

                        >.pro-arrow-wrapper {
                            .pro-arrow {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                .pro-inner-list-item {
                    padding-left: 14px !important;

                    .pro-inner-item {
                        padding: 8px 30px 8px 30px;

                        >.pro-icon-wrapper {
                            background: none;
                            width: auto;
                            min-width: auto;
                            height: auto;
                            line-height: auto;
                        }
                    }
                }
            }
        }

        &:not(.inner-submenu-arrows) {
            .pro-inner-list-item .pro-menu-item.pro-sub-menu .pro-inner-item .pro-arrow-wrapper {
                display: none;
            }
        }

        &.submenu-bullets {
            .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
                @extend %pro-item-prefix;
            }
        }

        &.shaped {
            .pro-menu-item {
                >.pro-inner-item {
                    >.pro-icon-wrapper {
                        background-color: $icon-bg-color;
                    }
                }
            }
        }

        &.square {
            .pro-menu-item {
                >.pro-inner-item {
                    >.pro-icon-wrapper {
                        border-radius: 0;
                    }
                }
            }
        }

        &.round {
            .pro-menu-item {
                >.pro-inner-item {
                    >.pro-icon-wrapper {
                        border-radius: 4px;
                    }
                }
            }
        }

        &.circle {
            .pro-menu-item {
                >.pro-inner-item {
                    >.pro-icon-wrapper {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    // styling when the sidebar is collapsed
    &.collapsed {
        .pro-menu {
            >ul {
                >.pro-menu-item {
                    >.pro-inner-item {

                        >.suffix-wrapper,
                        >.prefix-wrapper {
                            opacity: 0;
                        }
                    }

                    >.pro-inner-list-item {
                        background-color: $submenu-bg-color-collapsed;
                        z-index: 111;
                    }

                    position: relative;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                    }

                    &.pro-sub-menu {
                        position: relative;

                        >.pro-inner-item {
                            pointer-events: none;

                            >.pro-arrow-wrapper {
                                display: none;
                            }
                        }

                        >.pro-inner-list-item {
                            height: auto !important;
                            position: fixed;
                            visibility: hidden;
                            min-width: 220px;
                            max-width: 270px;
                            background-color: transparent;
                            max-height: 100%;
                            padding-left: 3px;

                            &.has-arrow {
                                padding-left: 10px;
                            }

                            >.popper-inner {
                                max-height: 100vh;
                                overflow-y: auto;
                                background-color: $submenu-bg-color-collapsed;
                                padding-left: 20px;
                                border-radius: 4px;
                            }
                        }

                        &:hover {
                            >.pro-inner-list-item {
                                transition: hidden, transform 0.3s;
                                visibility: hidden;
                                -webkit-transition: hidden, transform 0.3s;
                                -moz-transition: hidden, transform 0.3s;
                                -ms-transition: hidden, transform 0.3s;
                                -o-transition: hidden, transform 0.3s;
                            }

                            .pro-icon-wrapper {
                                .pro-icon {
                                    animation: swing ease-in-out 0.5s 1 alternate;
                                }
                            }
                        }

                        .pro-inner-list-item {

                            .pro-sub-menu-item,
                            .pro-inner-item {
                                padding: 8px 30px 8px 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    // styles for rtl
    &.rtl {
        .pro-menu {
            .pro-menu-item {
                .prefix-wrapper {
                    margin-right: 0;
                    margin-left: 5px;
                }

                >.pro-inner-item {
                    padding: 8px 20px 8px 35px;

                    >.pro-icon-wrapper {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }

                &.pro-sub-menu {
                    >.pro-inner-item {
                        >.pro-arrow-wrapper {
                            right: auto;
                            left: 20px;

                            .pro-arrow {
                                transform: rotate(135deg);
                            }
                        }
                    }

                    &.open {
                        >.pro-inner-item {
                            >.pro-arrow-wrapper {
                                .pro-arrow {
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }

                    .pro-inner-list-item {
                        padding-left: 0;
                        padding-right: $submenu-indent !important;

                        .pro-inner-item {
                            padding: 8px 15px 8px 30px;

                            &:before {
                                margin-right: 0;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }

        // styles for rtl and collapsed
        &.collapsed {
            .pro-menu {
                >ul {
                    >.pro-menu-item {
                        &.pro-sub-menu {
                            >.pro-inner-list-item {
                                padding-left: 0;
                                padding-right: 3px;

                                &.has-arrow {
                                    padding-right: 10px;
                                }

                                >.popper-inner {
                                    padding-left: 0;
                                    padding-right: 20px;
                                }
                            }

                            .pro-inner-list-item {

                                .pro-sub-menu-item,
                                .pro-inner-item {
                                    padding: 8px 5px 8px 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.popper-arrow {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
    right: 0;
    border-right: 7px solid $submenu-bg-color-collapsed;
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
    left: 0;
    border-left: 7px solid $submenu-bg-color-collapsed;
}

.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}

@mixin break-point {
    position: fixed;
    left: -$sidebar-width;

    &.collapsed {
        left: -$sidebar-collapsed-width;
    }

    &.toggled {
        left: 0;

        .overlay {
            display: block;
        }
    }

    &.rtl {
        left: auto;
        right: -$sidebar-width;

        &.collapsed {
            left: auto;
            right: -$sidebar-collapsed-width;
        }

        &.toggled {
            left: auto;
            right: 0;
        }
    }
}

.pro-sidebar {
    color: $sidebar-color;
    height: 100%;
    width: $sidebar-width;
    min-width: $sidebar-width;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;

    >.pro-sidebar-inner {
        background: $sidebar-bg-color;
        height: 100%;
        position: relative;
        z-index: 101;

        >img.sidebar-bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            opacity: 0.3;
            left: 0;
            top: 0;
            z-index: 100;
        }

        >.pro-sidebar-layout {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            z-index: 101;

            .pro-sidebar-header {
                border-bottom: 1px solid rgba($sidebar-color, 0.2);
            }

            .pro-sidebar-content {
                flex-grow: 1;
            }

            .pro-sidebar-footer {
                border-top: 1px solid rgba($sidebar-color, 0.2);
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#000, 0.3);
        z-index: 100;
        display: none;
    }

    &.collapsed {
        width: $sidebar-collapsed-width;
        min-width: $sidebar-collapsed-width;
    }

    &.rtl {
        text-align: right;
        direction: rtl;
    }

    &.xs {
        @media (max-width: $breakpoint-xs) {
            @include break-point;
        }
    }

    &.sm {
        @media (max-width: $breakpoint-sm) {
            @include break-point;
        }
    }

    &.md {
        @media (max-width: $breakpoint-md) {
            @include break-point;
        }
    }

    &.lg {
        @media (max-width: $breakpoint-lg) {
            @include break-point;
        }
    }

    &.xl {
        @media (max-width: $breakpoint-xl) {
            @include break-point;
        }
    }

    &.xxl {
        @media (max-width: $breakpoint-xxl) {
            @include break-point;
        }
    }
}

@mixin break-point {
    position: fixed;
    left: -85px;

    &.collapsed {
        left: -$sidebar-collapsed-width;
    }

    &.toggled {
        left: 0;

        .overlay {
            display: block;
        }
    }

    &.rtl {
        left: auto;
        right: -$sidebar-width;

        &.collapsed {
            left: auto;
            right: -$sidebar-collapsed-width;
        }

        &.toggled {
            left: auto;
            right: 0;
        }
    }
}

.toggleBtn {
    position: absolute;
    left: 10px;
}

@media (max-width: 768px) {
    .pro-sidebar {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.collapsed {
        position: fixed;
        left: 0px;
        z-index: 999999;
    }

}

.topOption {
    padding: 10px;
    text-align: center;
}

.topOption button {
    background-color: #0c1e35;
    border: 1px solid #fff;
    margin-bottom: 12px;
    padding: 10px 16px;
    color: #fff;
}

.topOption button:hover {
    background-color: #fff;
    border: 1px solid #dee2ec;
    margin-bottom: 12px;
    color: #0c1e35;
}

input.nosubmit {
    border: 1px solid #dee2ec;
    padding: 9px 4px 9px 40px;
    background: #dee2ec url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
	margin: 0 10px;
	width: 92%;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #000;
    background-color: none !important;
    border-color: none !important;
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5);
}

// checkbox

.selectDrop .select {
    position: relative;
    overflow: hidden;
    display: block;
    margin: auto;
    // width: 100%;
    height: 100%;
    border-bottom: 0px;
    border-radius: 3px;
    font-size: 15px;
    color: #fff;
    box-shadow: 0px 1em 2em -1.5em rgba(0, 0, 0, 0.5);
}

.selectDrop .select>i.toggle {
    position: absolute;
    z-index: 4;
    right: 1.5em;
    top: 1.6em;
    color: #fff;
}

.selectDrop .select .title,
.selectDrop .select .placeholder {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 15px !important;
    padding: 10px 0px 10px 0px;
    background: #0c1e35 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.selectDrop .select>input {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    cursor: pointer;
}

.selectDrop .select>input:checked div.options label.option .title {
    display: none !important;
}

.selectDrop .select>input:not(:checked) {
    z-index: 4;

}

.selectDrop .select>input:not(:checked)~label.option>span.title {
    display: none;
}

.selectDrop .select>input:disabled {
    cursor: no-drop;
}

.selectDrop .select>span.placeholder {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    color: #fff;
    border-top: 0px;
}

.selectDrop .select label.option {
    display: block;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    transition: all 1s ease-out;
}

.select label.option span.title {
    position: relative;
    z-index: 2;
    transition: background 0.3s ease-out;
    -webkit-transition: background 0.3s ease-out;
    -moz-transition: background 0.3s ease-out;
    -ms-transition: background 0.3s ease-out;
    -o-transition: background 0.3s ease-out;
}

.select label.option span.title:hover {
    background-color: #dee2ec !important;
    color: #0c1e35 !important;
    border: 1px solid #dee2ec;
}

.selectDrop .select label.option span.title:hover {
    color: #fff;
    background: rgb(255, 0, 0);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.selectDrop .select label.option input {
    display: none;
}

.selectDrop .select label.option input:checked~span.title {
    position: absolute;
    display: block;
    z-index: 3;
    top: 0px;
    font-size: 12px;
    background: #fff;
    border-top: 0px;
    box-shadow: none;
    color: inherit;
    width: 100%;
}

.selectDrop .select label.option input:disabled~span.title {
    background: #f9f9f9 !important;
    color: #aaa;
}

.selectDrop .select label.option input:disabled~span.title:hover {
    color: #aaa;
    background: none;
    cursor: no-drop;
}

.searchBox button {
    float: right;
}

.inlineLogo {
    display: inline-block;
    width: 100%;
}
.inlineLogo svg{
    padding-left: 5px;
    font-size: 22px;
}
.logoIcon {
    float: left;
    width: 80%;
}

.logoIcon img {
    width: 100%;
    height: 68px;
}

.toggleIcon {
    float: left;
    width: 20%;
}
.accountBtn{
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: #b3b8d4 !important;
}
.Abtn{
    padding: 8px 35px 8px 19px;
}
.selectBox select{
    background-color: #0c1e35 !important;
    padding: 8px 7px;
    font-size: 16px !important;
    text-align: center;
     color: #fff !important;
}
// .selectBox select .selectOption{
//     background-color: green;
//     padding: 80px 0px !important;
//     margin: 10px 0px !important;
//     line-height: 15px !important;
//     font-size: 15px !important;
//     min-height: 2.8em !important;
// }
.selectBox select option{
    background-color: #0c1e35 !important;
    background-color: green;
    padding: 8px 7px !important;
    margin: 10px 0px !important;
    line-height: 15px !important;
    text-align: left;
    font-size: 15px !important;
    min-height: 2.8em !important;
}
.selectBox select option:hover{
    background-color: yellow !important;
    padding: 8px 0px !important;
    margin: 10px 0px !important;
    line-height: 15px !important;
    text-align: center;
    font-size: 15px !important;
    color: red !important;
}
.selectDesign{
    padding: 8px 35px 8px 19px;
}
.selectDesign .css-1s2u09g-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0c1e35 !important;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    color: #fff !important;
}
.selectDesign .css-14el2xx-placeholder {
    color: hsl(0deg 0% 100%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}
.selectDesign .css-qc6sy-singleValue {
    color: hsl(0deg 0% 100%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}
.selectDesign .css-1pahdxg-control{
    background-color: #0c1e35 !important;
}
.selectDesign .css-4ljt47-MenuList {
    max-height:300px;
    overflow-y:auto;
    padding-bottom:4px;
    padding-top:4px;
    position:relative;
    -webkit-overflow-scrolling:touch;
    box-sizing:border-box;
    background-color: #fff;
   }
   .selectDesign .react-select__option{
    background-color: red !important;
    color: red !important;
}